<template>
  <div class="b2b-members-wrapper">
    <div class="b2b-members-header">
      <div class="title">Members</div>
      <ActionButton
        :width="'185px'"
        :height="'44px'"
        :text="'Invite member'"
        @click="goToMemberInvitation"
      />
    </div>
    <div class="b2b-members-list-wrapper">
      <div class="b2b-members-list-header">
        <div class="avatar-header">-</div>
        <div class="full-name-header">Full name</div>
        <div class="actions-header">Actions</div>
      </div>
      <div class="divider-line"></div>
      <div class="b2b-members-list">
        <B2BMemberItem
          v-for="member in b2bMembersList"
          :key="member.id"
          :member="member"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ActionButton from '../../common/ActionButton.vue'
import B2BMemberItem from './B2BMemberItem.vue'

export default {
  components: {
    ActionButton,
    B2BMemberItem,
  },
  data() {
    return {
      isLoading: true,
      errorMessage: '',
    }
  },
  async created() {
    await this.fetchInitialData()
  },
  computed: {
    ...mapState([
      'b2bMembers',
    ]),
    b2bMembersList() {
      return this.b2bMembers.list
    },
  },
  methods: {
    ...mapActions([
      'getB2BMembers',
    ]),
    async fetchInitialData() {
      try {
        await this.getB2BMembers()
      } catch (error) {
        this.errorMessage = 'Something went wrong, please, try again'
      } finally {
        this.isLoading = false
      }
    },
    goToMemberInvitation() {
      this.$router.push({ name: 'B2BMembersInvitation' })
    },
  },
  beforeUnmount() {
    // Clear b2bMembers list
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.b2b-members-wrapper {
  width: 100%;
  max-width: 720px;
  height: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  box-sizing: border-box;
  background-color: $whiteColor;

  .b2b-members-header {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: $fontSizeModalTitle;
    }

    .action-button {
      margin-top: 0;
    }
  }

  .b2b-members-list-wrapper {
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .b2b-members-list-header {
      width: 100%;
      height: 47px;
      display: flex;
      align-items: center;
      font-size: $fontSizeMinified;
      color: $fontColorGray;
      font-size: $fontSizeMinified;

      .avatar-header {
        width: 44px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .full-name-header {
        width: calc(100% - 122px);
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 16px;
      }

      .actions-header {
        width: 44px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
      }
    }

    .divider-line {
      width: 100%;
      height: 1px;
      background-color: $grayBorderColor;
    }

    .b2b-members-list {
      width: 100%;
      height: calc(100% - 47px);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }
  }
}
</style>