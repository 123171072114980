<template>
  <div class="member-list-item">
    <div class="item-content-wrapper">
      <div class="member-avatar">{{getMemberInitials}}</div>
      <div class="member-name">{{getMemberName}}</div>
      <div class="action-menu-container">
        <MoreActionsBtn
          :width="'44px'"
          :height="'44px'"
          :isActive="true"
          @click="openActionMenu"
        />
        <ActionMenu
          :width="'280px'"
          :height="'49px'"
          :top="'52px'"
          :isActionMenuShown="isActionMenuShown"
          @closeActionMenu="closeActionMenu"
        >
          <ActionMenuItem
            :text="'Delete'"
            @actionMenuItemClick="handleB2BMemberDeletion(member.id)"
          />
        </ActionMenu>
      </div>
    </div>
    <div class="divider-line"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem'

export default {
  props: ['member'],
  components: {
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
  },
  data() {
    return {
      isActionMenuShown: false,
    }
  },
  computed: {
    getMemberInitials() {
      if (this.member.full_name === 'temp_value') {
        return this.member.email[0]
      } else {
        const elements = this.member.full_name.split(' ')

        if (elements.length > 1) {
          return elements[0][0] + elements[elements.length - 1][0]
        }

        return elements[0][0]
      }
    },
    getMemberName() {
      return this.member.full_name === 'temp_value'
        ? this.member.email
        : this.member.full_name
    },
  },
  methods: {
    ...mapActions([
      'deleteB2BMember',
    ]),
    openActionMenu() {
      this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    async handleB2BMemberDeletion(memberId) {
      this.isActionMenuShown = false
      await this.deleteB2BMember(memberId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.member-list-item {
  width: 100%;
  height: 65px;
  min-height: 65px;
  display: flex;
  flex-direction: column;

  .item-content-wrapper {
    width: 100%;
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;

    .member-avatar {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $whiteColor;
      border-radius: 50%;
      background-color: $avatarPlaceholderColor;
    }

    .member-name {
      width: calc(100% - 122px);
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 16px;
    }

    .action-menu-container {
      width: 44px;
      height: 100%;
      margin-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .more-actions-btn {
        border: none;
        background-image: url('../../../assets/icons/more-white-icon.svg');
      }
    }
  }

  .divider-line {
    width: 100%;
    height: 1px;
    background-color: $grayBorderColor;
  }
}
</style>